import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reload"
export default class extends Controller {
  static values = { time: Number };

  connect() {
    this.startReloadTimer();
  }

  startReloadTimer() {
    const reloadTime = this.timeValue || 30;

    setTimeout(() => {
      location.reload();
    }, reloadTime * 1000);
  }
}
